/*
 * @Author: 冯杰
 * @Date: 2021-10-12 20:33:23
 * @LastEditTime: 2021-11-08 11:18:45
 * @FileName: 终端门店红包提现明细
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'TerminalWithdraw',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/cashRecord/cashRecordReport/findTerminalByConditions',
      requestType: 'GET',
      // params: {
      //   cashStatus: '2',
      // },
    };
  },
  components: {
    Modal,
  },
  methods: {
    setColumn(col) {
      const rowData = col;
      if (rowData.field === 'headPhotoPathUrl') {
        rowData.type = 'html';
        rowData.formatter = ({
          row,
        }) => `<a href=${row.headPhotoPathUrl}>下载</a>`;
      }
      if (rowData.field === 'channelName') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/mdmdictdata/list';
        rowData.optionsKey = {
          label: 'dictValue',
          value: 'dictValue',
        };
        rowData.isSearch = true;
        rowData.paramsName = 'dictValue';
        rowData.paramsFixed = {
          dictTypeCode: 'channel',
        };
      }
      return rowData;
    },
    modalClick({
      val,
      row,
    }) {
      let urls = '';
      let text = '';
      if (val.code === 'money') {
        urls = '/cps/v1/cashRecord/cashRecord/pay';
        text = '打款';
        if (row.id) {
          this.selection.push(row.id);
        }
        const list = this.tableData;
        list.forEach((item) => {
          this.selection.forEach((v) => {
            if (v === item.id) {
              if (item.cashStatus === '2') {
                this.operationClick(urls, text);
              } else {
                return this.$message.error('本次提现已打款成功，请勿重复打款');
              }
            }
          });
        });
      }
    },
    // 确认打款操作
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.patch(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('terminal_withdraw');
    this.searchFormData = {
      ...this.searchFormData,
      cashStatus: '2',
    };
  },
};
